import React, { useState, useEffect } from "react";
import { FiX, FiMenu, FiRefreshCw } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { ApiKickAll, ApiTransCheck } from "../../modules/api/GameApi";
import NumberFormat from "react-number-format";
import { WalletGetBalance } from "../../modules/api/WalletApi";
const Header = (props) => {
  const [username, setUsername] = useState("");
  const [wallet, setWallet] = useState(0);
  const { color = "default-color" } = props;
  useEffect(() => {
    window.addEventListener("load", function () {
      // console.log("All assets are loaded");
    });
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    // check permission
    _checkTransaction();
  }, []);
  const _checkTransaction = async () => {
    let localTransId = await localStorage.getItem("transId");
    if (localTransId) {
      let response = await ApiTransCheck(localTransId);
      if (response) {
        let apiReturn = response.data.data;
        if (apiReturn !== null && apiReturn[0] !== null) {
          setUsername(apiReturn[0].TCC_MEMBER_USERNAME);
          if (apiReturn[0].TCC_STATUS === "01") {
            // if (!apiReturn[0].TCC_MEMBER_WALLET_CENTER)
            //   apiReturn[0].TCC_MEMBER_WALLET_CENTER = 0;
            // if (!apiReturn[0].TCC_MEMBER_WALLET_GAME)
            //   apiReturn[0].TCC_MEMBER_WALLET_GAME = 0;
            let response = await WalletGetBalance(
              apiReturn[0].TCC_MEMBER_USERNAME
            );

            let walletReturn = response.data;
            // console.log(walletReturn);
            if (walletReturn.amount) setWallet(parseFloat(walletReturn.amount));
            await localStorage.setItem("transId", localTransId);
            await localStorage.setItem(
              "user",
              apiReturn[0].TCC_MEMBER_USERNAME
            );
            // setIsChecked(true);
          } else {
            window.location.replace(apiReturn.EXPIRED_REDIRECT_URL);
          }
        } else {
          // setIsChecked(false);
          window.location.replace("/error/000");
        }
      }
    } else {
      //  window.location.replace("/error/000");
      //
    }
    // console.log(localTransId);
    // console.log(member);
  };
  const _onMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const _onCLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  const _onWalletRefresh = async () => {
    let localUser = localStorage.getItem("user");
    // ApiKickAll(localTransId).then(({ data }) => {
    //   window.location.reload();
    // });
    let response = await WalletGetBalance(localUser);

    let walletReturn = response.data;
    // console.log(walletReturn);
    if (walletReturn.amount) setWallet(walletReturn.amount);
  };
  useEffect(() => {
    let interval = setInterval(() => {
      console.log(Date.now());
      _onWalletRefresh();
    }, 10000);
  }, []);
  // console.log(member);
  return (
    <header className={`header-area formobile-menu header--fixed ${color}`}>
      <div className="header-wrapper">
        <div className="header-left d-flex align-items-center">
          <div className="logo"></div>
          <nav className="mainmenunav d-lg-block ml--50">
            <Scrollspy
              className="mainmenu"
              items={["Home", "GameLive", "GameSlot", "GameCard"]}
              // items={["Home", "GameLive"]}
              currentClassName="is-current"
              offset={-200}
            >
              <li>
                <a href="/">หน้าหลัก</a>
              </li>
              <li>
                <a href="/g/live">คาสิโนสด</a>
              </li>
              <li>
                <a href="/g/slot">เกมส์สล็อต</a>
              </li>
              <li>
                <a href="/g/card">เกมส์ไพ่</a>
              </li>
            </Scrollspy>
          </nav>
        </div>
        <div className="header-right">
          <div className="social-share-inner text-white mr--20">
            {username.toUpperCase()}
          </div>
          <div className="header-btn">
            <button
              className="rn-button-style--2 btn-solid"
              onClick={() => {
                _onWalletRefresh();
              }}
            >
              <NumberFormat
                value={wallet}
                displayType={"text"}
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={"THB "}
              />
              {"  "}
              <FiRefreshCw />
            </button>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span
              onClick={() => {
                _onMenuTrigger();
              }}
              className="menutrigger text-white"
            >
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span
              onClick={() => {
                _onCLoseMenuTrigger();
              }}
              className="closeTrigger"
            >
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
